import React from "react";
import styles from "./FlipCard.module.css";

const FlipCard = ({ cardTitle, backContent, frontImage, backImage }) => {
  return (
    <div className={styles.flipCard}>
      <div className={styles.flipCardInner}>
        <div className={styles.flipCardFront}>
          <img src={frontImage} alt="Front" className={styles.cardImage} />
          <div>{cardTitle}</div>
        </div>
        <div className={styles.flipCardBack}>
          <div>{backContent}</div>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
