import React, { Component } from "react";
// import Zmage from "react-zmage";
import Fade from "react-reveal";
import FlipCard from "./FlipCard";

let id = 0;
class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "./images/portfolio/" + projects.image;

      return (
        <div key={id++}>
          <div style={{ width: "400px", height: "400px" }}>
            <FlipCard
              cardTitle={
                <p>
                  <b>{projects.title}</b>
                </p>
              }
              backContent={<p>{projects.category}</p>}
              frontImage={projectImage}
              backImage="https://path/to/back-image.jpg"
            />
            {/* <image alt={projects.title} src={projectImage} />
            <div style={{ textAlign: "center" }}>{projects.title}</div> */}
          </div>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="40px">
          <div className="row">
            <div className="twelve columns collapsed">
              <h1>Projects</h1>
              <center>
                <div
                  id="portfolio-wrapper"
                  className="bgrid-quarters s-bgrid-thirds cf"
                >
                  {projects}
                </div>
              </center>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
