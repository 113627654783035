import { useState } from "react";
import { send, init } from "@emailjs/browser";

function Email() {
  const [toSend, setToSend] = useState({
    from_name: "",
    subject: "",
    message: "",
    from_email: "",
  });

  const onSubmit = (e) => {
    init("user_IRQHSUFljrnR14az6Qoly");
    e.preventDefault();
    send(
      "service_y0lddns",
      "template_8k3p55f",
      toSend,
      "user_IRQHSUFljrnR14az6Qoly"
    )
      .then((response) => {
        alert("Success, Email Sent!");
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      })
      .catch((err) => {
        alert("Email Failed To Send, Please Try Again Later");
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="eight columns">
      <form onSubmit={onSubmit} id="contactForm">
        <fieldset>
          <div>
            <label htmlFor="contactName">
              Name <span className="required">*</span>
            </label>
            <input
              type="text"
              id="contactName"
              name="from_name"
              placeholder="Your Name"
              size="35"
              value={toSend.from_name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="contactEmail">
              Email <span className="required">*</span>
            </label>

            <input
              type="text"
              name="from_email"
              id="contactEmail"
              placeholder="Your email"
              size="35"
              value={toSend.from_email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="contactSubject">Subject</label>
            <input
              type="text"
              name="subject"
              id="contactSubject"
              size="35"
              placeholder="Message Subject"
              value={toSend.subject}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="contactMessage">
              Message <span className="required">*</span>
            </label>
            <textarea
              cols="50"
              rows="15"
              type="text"
              placeholder="Your message"
              name="message"
              id="contactMessage"
              value={toSend.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button className="submit" type="submit">
            Submit
          </button>
        </fieldset>
      </form>
    </div>
  );
}

export default Email;
